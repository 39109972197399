<template>
  <div>
    <hr />
    <div class="row" v-if="$parent.det_recorrido.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Punto inicio</th>
            <th scope="col">Fecha inicio</th>
            <th scope="col">Punto final</th>
            <th scope="col">Fecha final</th>
            <th scope="col">Tiempo (Min)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $parent.det_recorrido" :key="item.id">
            <td v-if="item.tif_sitio_origen">
              <strong> {{ item.tif_sitio_origen.nombre }} </strong>
            </td>
            <td v-else style="text-align:center">
              <span class="badge badge-warning">
                Sin Asignar
              </span>
            </td>
            <td class="text-center">{{ item.fecha_ini }}</td>
            <td v-if="item.tif_sitio_destino">
              <strong> {{ item.tif_sitio_destino.nombre }} </strong>
            </td>
            <td v-else style="text-align:center">
              <span class="badge badge-warning">
                Sin Asignar
              </span>
            </td>
            <td class="text-center">{{ item.fecha_fin }}</td>
            <td class="text-center">{{ item.tiempo_turno }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontro recorrido de este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TifTurnoRecorrido",

  data() {
    return {
      det_recorrido: {},
    };
  },
  methods: {},

  mounted() {},
};
</script>
